import { useCallback, useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  Slider,
  TextField,
  styled,
  LinearProgress,
  ThemeProvider,
  Tooltip,
  Grid,
  FormGroup,
  FormControlLabel,
  TablePagination,
  Box,
  Select,
  MenuItem,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import "./Incursion.scss";

import IncursionIcon from "../../assets/images/incursion-icon.svg";
import NowIcon from "../../assets/images/now-icon.svg";
import ScheduledIcon from "../../assets/images/scheduled-icon.svg";
import ColorLaunchIcon from "../../assets/images/schedule-image.svg";
import {
  ActionButton,
  ColorlibConnector,
  ColorlibStepIcon,
  FormInputTextField,
  pageTheme,
  PrimaryButton,
  SecondaryButton,
} from "../../ayudas/Themes";
import PackModal from "./PackageDetails/PackageDetail";
import {
  getPackageByIdAsync,
  getPackagePageAdvancedAsync,
} from "../../Services/Package";
import { AddExecutionAsync } from "../../Services/Executions";
import DetailmodalAgent from "../Agents/DetailAgents/DetailAgent";
import { includes } from "lodash";
import { PadNumbers } from "../../ayudas/GlobalData";
import { GetEnviromentAsymc } from "../../Services/Enviroment";
import axios from "axios";
import { env2 } from "../../ayudas/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import { enqueueSnackbar } from "notistack";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const styleCell = {
  borderBottom: "2px solid #6a688666",
};
const VectorSlider = styled(Slider)({
  color: "#c59d48",
  height: 8,

  "& .MuiSlider-markLabel": {
    color: "#fff",
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#11172E",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const Category = [
  "All",
  "KNOWN",
  "CHALLENGER",
  "UNCATEGORIZED",
  "INTERACTIVA",
  "ZERO",
];

export default function Incursion(props) {
  const { DataUser, ShowTitle, Reload } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [ifScheduled, setifScheduled] = useState(-1);
  const [Calendar, setCalendar] = useState(dayjs().add(10, "minutes"));
  const [safeDownload, setSafeDownload] = useState(
    DataUser?.SAFEDOWNLOAD ? DataUser.SAFEDOWNLOAD : "n"
  );
  const [Filter, setFilter] = useState(0);
  const [FilterName, setFilterName] = useState("");
  const [checked, setChecked] = useState([]);
  const [checkedClients, setCheckedClients] = useState([]);
  const [checkedClientsFullData, setCheckedClientsFullData] = useState([]);
  const [TotalListClients, setTotalListClients] = useState([]);
  const [TotalListOnline, setTotalListOnline] = useState([]);
  const [pagePackage, setPagePackage] = useState(0);
  const [PackageResult, setPackageResult] = useState(0);
  const [typePackages, settypePackages] = useState([]);
  const [rows, setrows] = useState(null);
  const [rowsClient, setrowsClient] = useState([]);
  const [nameIncurtion, setnameIncurtion] = useState("Incursion " + Calendar);
  const [vectors, setvectors] = useState(2);
  const marks = [
    { id: "NetworkSecurity", label: "Network", value: 0 },
    { id: "EPP", label: "Endpoint", value: 1 },
    { id: "RealExecution", label: "Execution", value: 2 },
  ];
  const [VectorExecution, setVectorExecution] = useState(2);
  const ShowTitleinter = ShowTitle ? ShowTitle : false;
  const [checkedVisible, setcheckedVisible] = useState(true);
  const [objectEPP, setobjectEPP] = useState(DataUser.TIMEEPP);
  const [objectNS, setobjectNS] = useState(DataUser.TIMENETWORKSECURITY);
  const [objectRe, setobjectRe] = useState(DataUser.TIMEREALEXECUTION);
  const [visiblesValues, setVisiblesValues] = useState("0,0,1");
  const [boolNS, setboolNS] = useState(false);
  const [boolEPP, setboolEPP] = useState(false);
  const [boolRE, setboolRE] = useState(false);
  const [disabledExecutionButton, setdisabledExecutionButton] = useState(false);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array
  const [rowsArtifacts, setrowsArtifacts] = useState([]);
  const [loadArtifactsArguments, setloadArtifactsArguments] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPagePackage(newPage);
  };
  useEffect(() => {
    if (activeStep === 1) {
      const GetData = async () => {
        let filterData = {};
        if (FilterName !== "") filterData.name = FilterName;
        if (Filter !== 0) {
          for (let index = 0; index < typePackages.length; index++) {
            const element = typePackages[index];

            const el = Category[Filter];
            if (element.name.toUpperCase() === el) {
              filterData.Type = [element.id];
              break;
            }
          }
        }
        const response = await getPackagePageAdvancedAsync(
          8,
          pagePackage + 1,
          "lastModification",
          "desc",
          filterData
        );

        if (response && response?.info) {
          setrows(response.packages);
          setPackageResult(response.info.neto);
        } else {
          enqueueSnackbar("Without results", { variant: "info" });
          setrows([]);
          setPackageResult(0);
        }
      };
      GetData();
    }
  }, [pagePackage, Filter, FilterName, typePackages, activeStep]);

  const getTypes = useCallback(async () => {
    const response = JSON.parse(localStorage.getItem("packageTypes"));
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          name: element.name,
        });
      });
      settypePackages(array);
    }
  }, []);

  useEffect(() => {
    cookies.set("CurrentPageDataPagination", "777", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function GetData() {
      let url = env2.api + endPoint.clients;
      const data = await AxiosFetch("GET", url, source);
      const response = data?.msg ? data.msg : null;
      if (response?.result && response.query) {
        const sortedResults = response.result.sort((a, b) => {
          if (a.online && !b.online) {
            return -1;
          } else if (!a.online && b.online) {
            return 1;
          } else {
            return 0;
          }
        });
        setTotalListClients(sortedResults);
        if (ifScheduled === 0) {
          setrowsClient(TotalListOnline);
        } else {
          setrowsClient(sortedResults);
        }
      } else {
        setTotalListClients([]);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [cookies, Reload]);

  useEffect(() => {
    if (TotalListClients.length > 0) {
      async function GetData() {
        let onlineClientsResponse = TotalListClients;
        let array = [];
        onlineClientsResponse.forEach((element) => {
          if (element.online) {
            array.push(element);
          }
        });
        setTotalListOnline(array);
      }
      GetData();
    }
  }, [TotalListClients]);

  useEffect(() => {
    if (ifScheduled === 0 && TotalListOnline.length === 0) {
      enqueueSnackbar("No Endpoints connected for an on-demand emulation", {
        variant: "info",
      });
      setifScheduled(-1);
    }
  }, [TotalListOnline.length, ifScheduled]);

  useEffect(() => {
    if (visiblesValues === "1,0,0") {
      setboolNS(false);
      setboolEPP(true);
      setboolRE(true);
    } else if (visiblesValues === "0,1,0") {
      setboolNS(false);
      setboolEPP(false);
      setboolRE(true);
    } else if (visiblesValues === "0,0,1") {
      setboolNS(false);
      setboolEPP(false);
      setboolRE(false);
    }
  }, [visiblesValues]);

  const handleChangeChecked = (event) => {
    setcheckedVisible(event.target.checked);
    setobjectEPP(DataUser.TIMEEPP);
    setobjectNS(DataUser.TIMENETWORKSECURITY);
    setobjectRe(DataUser.TIMEREALEXECUTION);
    setSafeDownload(DataUser.SAFEDOWNLOAD);
  };

  const handleFilterName = (env) => {
    if (env.target.value !== "") {
      let filter = env.target.value;
      setFilterName(filter);
    } else {
      setFilterName("");
    }
    setPagePackage(0);
  };

  const handleName = (event) => {
    let name = event.target.value;
    if (name === " ") {
      name = "";
    }
    setnameIncurtion(name);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleToggleClients = (value) => () => {
    const currentIndex = checkedClients.indexOf(value._id);
    const newChecked = [...checkedClients];
    const newCheckedFullData = [...checkedClientsFullData];

    if (currentIndex === -1) {
      newChecked.push(value._id);
      newCheckedFullData.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newCheckedFullData.splice(currentIndex, 1);
    }

    setCheckedClients(newChecked);
    setCheckedClientsFullData(newCheckedFullData);
  };
  const [previewChecked, setpreviewChecked] = useState([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === 1) {
      getTypes();
    }
    if (activeStep + 1 === 2) {
      if (JSON.stringify(checked) !== JSON.stringify(previewChecked)) {
        setrowsArtifacts([]);
      }
    }
    if (activeStep + 1 === 3) {
      if (ifScheduled === 0) {
        setrowsClient(TotalListOnline);
      } else {
        setrowsClient(TotalListClients);
      }
    }
    if (activeStep + 1 === 4) {
      let tempDate = new Date();
      setnameIncurtion("Incursion " + tempDate.toString());
    }
    if (activeStep + 1 === 6) {
      let bool = checkOnlineClients();
      if (bool) {
        enqueueSnackbar("The Endpoint(s) selected are not found online", {
          variant: "info",
        });
      }
      ValidateAgentsConExecution();
      setdisabledExecutionButton(bool);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep - 1 === 3) {
      if (VectorExecution === 0) {
        setVisiblesValues("1,0,0");
      }
      if (VectorExecution === 1) {
        setVisiblesValues("0,1,0");
      }
      if (VectorExecution === 2) {
        setVisiblesValues("0,0,1");
      }
    }
    if (activeStep - 1 === 1) {
      setpreviewChecked(checked);
    }
  };
  const checkOnlineClients = () => {
    let flag = true;
    if (ifScheduled === 0) {
      for (let i = 0; i < checkedClients.length; i++) {
        for (let index = 0; index < TotalListOnline.length; index++) {
          if (TotalListOnline[index]._id === checkedClients[i]) {
            flag = false;
            break;
          }
        }
        if (flag === false) {
          break;
        }
      }
    } else {
      flag = false;
    }
    return flag;
  };

  const CreateExecution = async () => {
    setdisabledExecutionButton(true);
    let fk_package_test = [];
    let artifactsWithArguments = [];
    let packageNameEvaluate = rowsArtifacts[0].packageName;
    let packageID = rowsArtifacts[0].packageID;

    for (let index = 0; index < rowsArtifacts.length; index++) {
      const element = rowsArtifacts[index];
      if (element.packageName === packageNameEvaluate) {
        if (element.argument !== "") {
          artifactsWithArguments.push({
            fk_artifact: element.artifactID,
            arg: element.argument,
          });
        }
      } else {
        if (artifactsWithArguments.length > 0) {
          fk_package_test.push({
            ID: packageID,
            artifacts: artifactsWithArguments,
          });
        } else {
          fk_package_test.push({ ID: packageID });
        }
        packageNameEvaluate = element.packageName;
        packageID = element.packageID;
        artifactsWithArguments = [];
        if (element.argument !== "") {
          artifactsWithArguments.push({
            fk_artifact: element.artifactID,
            arg: element.argument,
          });
        }
      }
    }
    if (artifactsWithArguments.length > 0) {
      fk_package_test.push({
        ID: packageID,
        artifacts: artifactsWithArguments,
      });
    } else {
      fk_package_test.push({ ID: packageID });
    }
    console.log(fk_package_test);

    let clist = [];
    let plist = [];
    for (let index = 0; index < checked.length; index++) {
      plist.push(checked[index]);
    }
    for (let index = 0; index < checkedClients.length; index++) {
      clist.push(checkedClients[index]);
    }

    if (!ifScheduled) {
      const data = {
        name: nameIncurtion,
        fk_package: fk_package_test,
        clients: clist,
        safeDownload: safeDownload,
        vector: marks[VectorExecution].id,
        typeExecution: "onDemand",
        time_NetworkSecurity: parseInt(objectNS),
        time_EPP: parseInt(objectEPP),
        time_RealExecution: parseInt(objectRe),
      };
      const response = await AddExecutionAsync(data);
      if (response) {
        let idRequest = response._id;
        props.Execute(idRequest);
        props.Back();
      }
    } else {
      let now = new Date();
      var diffMs = Calendar - now;
      if (diffMs < 580003) {
        setActiveStep(0);
        enqueueSnackbar("The schedule must be at least 10 minutes apart", {
          variant: "info",
        });
      } else {
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        let dateZ = Calendar.toISOString();
        let dateexec = dateZ.split("T");
        let diaZ = dateexec[0];
        let separate = dateexec[1].split(":");
        let horaZ = separate[0] + ":" + separate[1];
        const data = {
          name: nameIncurtion,
          fk_package: fk_package_test,
          clients: clist,
          safeDownload: safeDownload,
          vector: marks[VectorExecution].id,
          typeExecution: "scheduled",
          startDate: diaZ,
          startTime: horaZ,
          time_NetworkSecurity: parseInt(objectNS),
          time_EPP: parseInt(objectEPP),
          time_RealExecution: parseInt(objectRe),
        };
        const response = await AddExecutionAsync(data);
        if (response) {
          enqueueSnackbar(
            "Execution scheduled in " +
              diffDays +
              " days, " +
              diffHrs +
              " hours, " +
              diffMins +
              " minutes",
            { variant: "success" }
          );
          props.Back();
        }
      }
    }
  };

  const handleFilter = (val) => {
    setFilter(val);

    setFilterName("");
  };
  const handleChange = (event, vector) => {
    setvectors(vector);
    setVectorExecution(vector);
    if (vector === 0) {
      setVisiblesValues("1,0,0");
    }
    if (vector === 1) {
      setVisiblesValues("0,1,0");
    }
    if (vector === 2) {
      setVisiblesValues("0,0,1");
    }
  };
  // Validation for insider
  const [isolationProtectWarning, setIsolationProtectWarning] = useState(false);

  const [allInsiderConnectMessage, setAllInsiderConnectMessage] =
    useState(false);

  const ValidateAgentsConExecution = async () => {
    let agentCon = TotalListOnline.map(
      (element) => element.workUser[0].hostname
    );
    let agentsInExecution = checkedClientsFullData.map(
      (element) => element.workUser[0].hostname
    );
    let INSOLATIONPROTECTION;

    const response = await GetEnviromentAsymc();
    if (response) {
      response.forEach((element) => {
        if (element.name === "EXECUTIONS") {
          element.settings.forEach((setting) => {
            if (setting.variable === "INSOLATIONPROTECTION") {
              INSOLATIONPROTECTION = setting.value;
            }
          });
        }
      });
    }
    if (INSOLATIONPROTECTION === "Y" && checkedClientsFullData) {
      for (let index = 0; index < checkedClientsFullData.length; index++) {
        const element = checkedClientsFullData[index];
        if (!element.isolation && element.online && element.OS !== 2) {
          setIsolationProtectWarning(true);
          break;
        }
      }
    }

    if (TotalListOnline.length > 0) {
      let totalInsiders = 0;
      agentsInExecution.forEach((element) => {
        let validate = agentCon.indexOf(element);
        if (validate !== -1) {
          totalInsiders++;
        }
      });
      if (totalInsiders === agentsInExecution.length) {
        setAllInsiderConnectMessage(true);
      }
    }
  };

  useEffect(() => {
    async function getPackagesDetails() {
      if (checked && activeStep === 2 && rowsArtifacts.length === 0) {
        // Crear un array de promesas
        const promises = checked.map((element) => getPackageByIdAsync(element));

        // Esperar a que todas las promesas se resuelvan
        const array = await Promise.all(promises);

        let newDataGrid = [];
        array.forEach((pack, indexPack) => {
          if (pack?.artifacts.length > 0) {
            pack.artifacts.forEach((artifact, indexArtifact) => {
              newDataGrid.push({
                id: indexPack + "-" + indexArtifact,
                packageID: pack.id,
                packageName: pack.name,
                artifactID: artifact.id,
                artifactName: artifact.name,
                argument: "",
              });
            });
          } else {
            enqueueSnackbar(
              'The package "' + pack.name + "\" doesn't have content threats",
              { variant: "warning" }
            );
          }
        });
        setrowsArtifacts(newDataGrid);
        setloadArtifactsArguments(true);
      }
    }

    getPackagesDetails();
  }, [checked, activeStep, rowsArtifacts]);

  return (
    <ThemeProvider theme={pageTheme}>
      <Box style={{ width: "100%" }}>
        {!ShowTitleinter ? (
          <Typography variant="pageTitle">
            <img src={IncursionIcon} alt="" className="rt-icon-header" />
            Incursion
          </Typography>
        ) : null}
        <Box
          style={{
            width: "100%",
            backgroundColor: "#090f1a",
            border: "1px solid #1e1c3a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "12px",
            marginTop: "24px",
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            <Step key={0}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Type:</Typography>
              </StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Package:</Typography>
              </StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Arguments:</Typography>
              </StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Vector:</Typography>
              </StepLabel>
            </Step>
            <Step key={4}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Endpoint:</Typography>
              </StepLabel>
            </Step>
            <Step key={5}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Name:</Typography>
              </StepLabel>
            </Step>
            <Step key={6}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Emulation:</Typography>
              </StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 ? (
            <Box
              style={{
                backgroundColor: "transparent",
                border: "1px solid #090f1a",
                borderRadius: "14px",
                padding: "15px",
                maxWidth: "700px",
                margin: "0 auto",
                marginTop: "24px",
              }}
            >
              <Typography variant="subTitleContent">Type Incursion</Typography>
              <Box
                style={{
                  textAlign: "center",
                  padding: "15px",
                }}
              >
                <Button
                  onClick={() => {
                    setifScheduled(0);
                  }}
                  style={{
                    backgroundColor: ifScheduled === 0 ? "#c59d48" : "#14122a",
                    borderColor: ifScheduled === 0 ? "#c59d48" : "#14122a",
                    color: "#fff",
                    fontSize: "15px",
                    textTransform: "capitalize",
                    padding: "20px 20px",
                    width: "150px",
                    lineHeight: "19px",
                    fontHeight: "600",
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "7px",
                  }}
                >
                  <img
                    src={NowIcon}
                    alt=""
                    style={{ marginRight: "7px", verticalAlign: "middle" }}
                  />
                  Now
                </Button>
                <Button
                  onClick={() => {
                    setifScheduled(1);
                  }}
                  style={{
                    backgroundColor: ifScheduled === 1 ? "#c59d48" : "#14122a",
                    borderColor: ifScheduled === 1 ? "#c59d48" : "#14122a",
                    color: "#fff",
                    fontSize: "15px",
                    textTransform: "capitalize",
                    padding: "20px 20px",
                    width: "150px",
                    lineHeight: "19px",
                    fontHeight: "600",
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "7px",
                  }}
                >
                  <img
                    src={ScheduledIcon}
                    alt=""
                    style={{ marginRight: "7px", verticalAlign: "middle" }}
                  />
                  Scheduled
                </Button>
              </Box>
              <Box
                className="textfield"
                style={{
                  display: ifScheduled === 1 ? "block" : "none",
                  marginLeft: "30%",
                  marginRight: "30%",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={Calendar}
                    onChange={(newValue) => {
                      setCalendar(newValue);
                    }}
                    min={dayjs().add(10, "minutes")}
                  />
                </LocalizationProvider>
              </Box>
              {ifScheduled === -1 ||
              (ifScheduled === 1 && Calendar === "") ||
              (ifScheduled === 0 && TotalListOnline.length === 0) ? (
                <SecondaryButton
                  disabled
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "24px",
                    display: "block",
                  }}
                >
                  Next
                </SecondaryButton>
              ) : (
                <PrimaryButton
                  onClick={handleNext}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "24px",
                    display: "block",
                  }}
                >
                  Next
                </PrimaryButton>
              )}
            </Box>
          ) : null}
          {activeStep === 1 ? (
            <Box style={{ padding: "50px 0" }}>
              {!rows ? (
                <Box
                  style={{
                    backgroundColor: "#090f1a",
                    border: "1px solid #1e1c3a",
                    padding: "15px",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                    borderRadius: "12px",
                    marginBottom: "24px",
                    textAlign: "center",
                  }}
                >
                  <LinearProgress color="warning" />
                </Box>
              ) : (
                <Box style={{ width: "100%" }}>
                  <Typography variant="subTitleContent">
                    Select Package
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <Box
                      style={{
                        marginLeft: "16px",
                        marginBottom: "24px",
                        width: "50%",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleFilter(0);
                        }}
                        style={{
                          backgroundColor:
                            Filter === 0 ? "#c59d48" : "rgb(10, 8, 24)",
                          border: "1px solid #1e1c3a",
                          boxShadow: " 0px 10px 20px rgb(0 0 0 / 5%)",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#fff",
                          padding: "12px 14px",
                          textTransform: "capitalize",
                          borderRadius: "12px 0px 0px 12px",
                          borderLeft: "1px solid #1e1c3a",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                          }}
                        >
                          All
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          handleFilter(1);
                        }}
                        style={{
                          backgroundColor:
                            Filter === 1 ? "#c59d48" : "rgb(10, 8, 24)",
                          border: "1px solid #1e1c3a",
                          boxShadow: " 0px 10px 20px rgb(0 0 0 / 5%)",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#fff",
                          borderRadius: "0",
                          padding: "12px 14px",
                          textTransform: "capitalize",
                          borderLeft: "0",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                          }}
                        >
                          Known
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          handleFilter(2);
                        }}
                        style={{
                          backgroundColor:
                            Filter === 2 ? "#c59d48" : "rgb(10, 8, 24)",
                          border: "1px solid #1e1c3a",
                          boxShadow: " 0px 10px 20px rgb(0 0 0 / 5%)",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#fff",
                          borderRadius: "0",
                          padding: "12px 14px",
                          textTransform: "capitalize",
                          borderLeft: "0",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                          }}
                        >
                          Challenger
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          handleFilter(3);
                        }}
                        style={{
                          backgroundColor:
                            Filter === 3 ? "#c59d48" : "rgb(10, 8, 24)",
                          border: "1px solid #1e1c3a",
                          boxShadow: " 0px 10px 20px rgb(0 0 0 / 5%)",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#fff",
                          borderRadius: "0",
                          padding: "12px 14px",
                          textTransform: "capitalize",
                          borderLeft: "0",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                          }}
                        >
                          Uncategorized
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          handleFilter(4);
                        }}
                        style={{
                          backgroundColor:
                            Filter === 4 ? "#c59d48" : "rgb(10, 8, 24)",
                          border: "1px solid #1e1c3a",
                          boxShadow: " 0px 10px 20px rgb(0 0 0 / 5%)",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#fff",
                          borderRadius: "0",
                          padding: "12px 14px",
                          textTransform: "capitalize",
                          borderLeft: "0",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                          }}
                        >
                          Interactive
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          handleFilter(5);
                        }}
                        style={{
                          backgroundColor:
                            Filter === 5 ? "#c59d48" : "rgb(10, 8, 24)",
                          border: "1px solid #1e1c3a",
                          boxShadow: " 0px 10px 20px rgb(0 0 0 / 5%)",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#fff",
                          padding: "12px 14px",
                          textTransform: "capitalize",
                          borderRadius: "0px 12px 12px 0px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                          }}
                        >
                          Zero
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      style={{
                        marginBottom: "24px",
                        width: "40%",
                      }}
                    >
                      <input
                        onChange={handleFilterName}
                        placeholder="Search name"
                        fullWidth
                        value={FilterName}
                        style={{
                          border: "1px solid #464366",
                          backgroundColor: "rgb(10, 8, 24)",
                          borderRadius: "15px",
                          height: "49px",
                          fontSize: "14px",
                          minWidth: "100px",
                          padding: "3px 15px",
                          color: "#8e97ad",
                          float: "right",
                          width: "100%",
                        }}
                      ></input>
                    </Box>

                    <Box
                      style={{
                        padding: "5px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        width: "100%",
                        minHeight: "400px",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: "1rem",
                          display: "flex",
                          flexWrap: "wrap",
                          height: "400px",
                        }}
                      >
                        {rows !== "Without results" && rows.length > 0 ? (
                          rows.map((row, index) => {
                            return (
                              <Box
                                style={{
                                  flex: "0 0 25%",
                                  maxWidth: "250px",
                                  marginLeft: "15px",
                                  marginBottom: "16px",
                                  textAlign: "center",
                                }}
                              >
                                <Box
                                  key={row.id}
                                  style={{
                                    backgroundColor: "#0a0818",
                                    borderRadius: "10px",
                                    padding: "25px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box style={{ display: "block" }}>
                                    <PackModal Row={row} DataUser={DataUser} />
                                  </Box>
                                  <Box
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "14px",
                                      paddingTop: "1rem",
                                      display: "block",
                                    }}
                                  ></Box>
                                  <Tooltip title={row.name}>
                                    <Typography
                                      style={{
                                        color: "#66638f",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        maxWidth: "190px",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        minWidth: "190px",
                                      }}
                                      onClick={handleToggle(row.id)}
                                    >
                                      <Checkbox
                                        style={{ color: "#66638f" }}
                                        tabIndex={-1}
                                        checked={checked.indexOf(row.id) !== -1}
                                      />
                                      {row.name}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </Box>
                            );
                          })
                        ) : (
                          <Typography>Without results</Typography>
                        )}
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          paddingRight: "120px",
                        }}
                      >
                        <TablePagination
                          colSpan={3}
                          rowsPerPageOptions={[8]}
                          count={PackageResult}
                          rowsPerPage={8}
                          page={pagePackage}
                          onPageChange={handleChangePage}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box style={{ width: "100%", textAlign: "center" }}>
                    <SecondaryButton
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      style={{
                        alignWtems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        marginRight: "7px",
                        marginTop: "24px",
                        display: "inline-block",
                      }}
                    >
                      Back
                    </SecondaryButton>
                    {checked.length < 1 ? (
                      <SecondaryButton
                        disabled
                        onClick={handleNext}
                        style={{
                          alignWtems: "center",
                          justifyContent: "center",
                          margin: "auto",
                          marginTop: "24px",
                          display: "inline-block",
                        }}
                      >
                        Next
                      </SecondaryButton>
                    ) : (
                      <PrimaryButton
                        onClick={handleNext}
                        style={{
                          alignWtems: "center",
                          justifyContent: "center",
                          margin: "auto",
                          marginTop: "24px",
                          display: "inline-block",
                        }}
                      >
                        Next
                      </PrimaryButton>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          ) : null}
          {activeStep === 2 && (
            <Box style={{ padding: "50px 0" }}>
              <Typography variant="subTitleContent">
                Threat Arguments
              </Typography>
              <div style={{ overflow: "auto", height: "500px", width: "100%" }}>
                {loadArtifactsArguments ? (
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{ background: "#0d0b21" }}>
                        <TableCell>Package Name</TableCell>
                        <TableCell>Threat Name</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsArtifacts.map((row) => (
                        <TableRow key={row.id} sx={{ height: "20px" }}>
                          <TableCell sx={styleCell}>
                            {row.packageName}
                          </TableCell>
                          <TableCell sx={styleCell}>
                            {row.artifactName}
                          </TableCell>
                          <TableCell sx={styleCell}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              value={row.argument}
                              onChange={(event) => {
                                let array = [...rowsArtifacts];
                                for (
                                  let index = 0;
                                  index < array.length;
                                  index++
                                ) {
                                  const element = array[index];
                                  if (element.id === row.id) {
                                    element.argument = event.target.value;
                                    break;
                                  }
                                }
                                setrowsArtifacts(array);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <CircularProgress />
                )}
              </div>
              <Box style={{ width: "100%", textAlign: "center" }}>
                <SecondaryButton
                  onClick={handleBack}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginRight: "7px",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Back
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleNext}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Next
                </PrimaryButton>
              </Box>
            </Box>
          )}
          {activeStep === 3 ? (
            <Box style={{ padding: "50px 0" }}>
              <Typography variant="subTitleContent">Select Vector</Typography>
              <Box className="textfield">
                {!marks ? (
                  <Box
                    style={{
                      backgroundColor: "#090f1a",
                      border: "1px solid #1e1c3a",
                      padding: "15px",
                      boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                      borderRadius: "12px",
                      marginBottom: "24px",
                      textAlign: "center",
                    }}
                  >
                    <LinearProgress color="warning" />
                  </Box>
                ) : (
                  <Box style={{ width: "100%", textAlign: "center" }}>
                    <Box
                      style={{
                        padding: "10%",
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "14px",
                        backgroundColor: "transparent",
                        color: "#fff",
                      }}
                    >
                      <VectorSlider
                        onChange={handleChange}
                        defaultValue={2}
                        value={vectors}
                        marks={marks}
                        min={0}
                        max={2}
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              checked={checkedVisible}
                              onChange={handleChangeChecked}
                            />
                          }
                          label="Default Time"
                        />
                      </FormGroup>
                      {!checkedVisible ? (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <FormInputTextField
                                disabled={boolNS}
                                label="Time of Network"
                                type="number"
                                onKeyDown={(e) => {
                                  if (!includes(PadNumbers, e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                fullWidth
                                value={objectNS}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(event) => {
                                  if (event.target.value !== ".") {
                                    if (event.target.value <= 300) {
                                      setobjectNS(event.target.value);
                                    }
                                  }
                                }}
                                InputProps={{
                                  inputProps: { min: 1, max: 300 },
                                }}
                              />
                            </Grid>
                            <Grid item xs={3.5}>
                              <FormInputTextField
                                disabled={boolEPP}
                                //id="outlined-number"
                                onKeyDown={(e) => {
                                  if (!includes(PadNumbers, e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                label="Time of Endpoint"
                                type="number"
                                fullWidth
                                value={objectEPP}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(event) => {
                                  if (event.target.value !== ".") {
                                    if (event.target.value <= 300) {
                                      setobjectEPP(event.target.value);
                                    }
                                  }
                                }}
                                InputProps={{
                                  inputProps: { min: 1, max: 300 },
                                }}
                              />
                            </Grid>
                            <Grid item xs={3.5}>
                              <FormInputTextField
                                disabled={boolRE}
                                InputProps={{
                                  inputProps: { min: 1, max: 300 },
                                }}
                                //id="outlined-number"
                                label="Time of Execution"
                                type="number"
                                onKeyDown={(e) => {
                                  if (!includes(PadNumbers, e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                fullWidth
                                value={objectRe}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(event) => {
                                  if (event.target.value !== ".") {
                                    if (event.target.value <= 300) {
                                      setobjectRe(event.target.value);
                                    }
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box style={{ width: "100%", textAlign: "center" }}>
                <SecondaryButton
                  onClick={handleBack}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginRight: "7px",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Back
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleNext}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Next
                </PrimaryButton>
              </Box>
            </Box>
          ) : null}
          {activeStep === 4 ? (
            <Box style={{ padding: "50px 0" }}>
              {!rowsClient ? (
                <Box
                  style={{
                    backgroundColor: "#090f1a",
                    border: "1px solid #1e1c3a",
                    padding: "15px",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                    borderRadius: "12px",
                    marginBottom: "24px",
                    textAlign: "center",
                  }}
                >
                  <LinearProgress color="warning" />
                </Box>
              ) : (
                <Box style={{ width: "100%" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <Typography variant="subTitleContent">
                        Select Endpoints
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        padding: "5px",
                        maxHeight: "350px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        width: "100%",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: "1rem",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {rowsClient.length > 0 ? (
                          rowsClient.map((row, index) => {
                            return (
                              <Box
                                style={{
                                  flex: "0 0 25%",
                                  maxWidth: "250px",
                                  marginLeft: "15px",
                                  marginBottom: "16px",
                                  textAlign: "center",
                                }}
                              >
                                <Box
                                  key={row._id}
                                  style={{
                                    backgroundColor: "#0a0818",
                                    borderRadius: "10px",
                                    padding: "25px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "200px",
                                  }}
                                >
                                  <Box style={{ display: "block" }}>
                                    <DetailmodalAgent
                                      Client={row}
                                      SelectIcon={false}
                                      DetailsOff={false}
                                      ResetInsider={(e) => {
                                        props.ResetInsider(e);
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "14px",
                                      paddingTop: "1rem",
                                      display: "block",
                                    }}
                                  ></Box>

                                  <Typography
                                    style={{
                                      color: "#66638f",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleToggleClients(row)}
                                  >
                                    <Checkbox
                                      style={{ color: "#66638f" }}
                                      tabIndex={-1}
                                      checked={
                                        checkedClients.indexOf(row._id) !== -1
                                      }
                                    />
                                    {row?.hostname
                                      ? row.hostname
                                      : row.workUser[0].hostname}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })
                        ) : (
                          <Box
                            style={{
                              width: "100%",
                              backgroundColor: "#0a0818",
                              borderRadius: "10px",
                              padding: "25px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography align="center">
                              Without results
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box style={{ width: "100%", textAlign: "center" }}>
                    <SecondaryButton
                      onClick={handleBack}
                      style={{
                        alignWtems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        marginTop: "24px",
                        marginRight: "7px",
                        display: "inline-block",
                      }}
                    >
                      Back
                    </SecondaryButton>
                    {checkedClients < 1 ? (
                      <SecondaryButton
                        disabled
                        style={{
                          alignWtems: "center",
                          justifyContent: "center",
                          margin: "auto",
                          marginTop: "24px",
                          display: "inline-block",
                        }}
                      >
                        Next
                      </SecondaryButton>
                    ) : (
                      <PrimaryButton
                        onClick={handleNext}
                        style={{
                          alignWtems: "center",
                          justifyContent: "center",
                          margin: "auto",
                          marginTop: "24px",
                          display: "inline-block",
                        }}
                      >
                        Next
                      </PrimaryButton>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          ) : null}
          {activeStep === 5 ? (
            <Box
              style={{
                marginTop: "50px",
                width: "100%",
                borderRadius: "14px",
                backgroundColor: "transparent",
              }}
            >
              <Typography variant="subTitleContent" marginLeft={"1%"}>
                Emulation Name
              </Typography>
              <Box className="textfield">
                <Box
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Box
                    style={{
                      marginLeft: "5%",
                      width: "90%",
                      textAlign: "center",
                    }}
                  >
                    <TextField
                      className="Inputiincurtion"
                      id="NameIncurtion"
                      fullWidth
                      placeholder={"Incursion " + Calendar}
                      onChange={handleName}
                      value={nameIncurtion}
                    />
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        textAlign: "center",
                        fontWeight: "600",
                      }}
                    >
                      Do you want to activate the secure download?
                    </Typography>
                    <Select
                      style={{ width: "50px" }}
                      onChange={(e) => {
                        setSafeDownload(e.target.value);
                      }}
                      //required
                      id="outlined-basic"
                      label="SSL enable"
                      variant="outlined"
                      type="text"
                      value={safeDownload}
                    >
                      <MenuItem value={"n"}>No</MenuItem>
                      <MenuItem value={"Y"}>Yes</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                <SecondaryButton
                  onClick={handleBack}
                  style={{
                    marginRight: "7px",
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Back
                </SecondaryButton>
                <PrimaryButton
                  disabled={nameIncurtion === ""}
                  onClick={handleNext}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginLeft: "7px",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Next
                </PrimaryButton>
              </Box>
            </Box>
          ) : null}
          {activeStep === 6 ? (
            <Box style={{ padding: "50px 0" }}>
              <Box className="textfield">
                <Box style={{ width: "100%", textAlign: "center" }}>
                  <Box
                    style={{
                      marginLeft: "5%",
                      width: "90%",
                      textAlign: "center",
                    }}
                  >
                    <img src={ColorLaunchIcon} alt="" />
                  </Box>
                  {isolationProtectWarning ? (
                    <Typography id="keep-mounted-modal-title" variant="h6">
                      The Insider(s) doesn't have the protection of the
                      isolation,
                    </Typography>
                  ) : null}
                  {allInsiderConnectMessage ? (
                    <Typography id="keep-mounted-modal-title" variant="h6">
                      {!ShowTitleinter ? (
                        <Typography>
                          {ifScheduled === 0
                            ? "Are you sure you want to execute now?"
                            : "Are you sure you want to scheduled?"}
                        </Typography>
                      ) : (
                        "Are you sure you want to create a emulation?"
                      )}
                    </Typography>
                  ) : null}
                  <Typography
                    style={{
                      marginLeft: "15px",
                      marginBottom: "16px",
                      fontSize: "16px",
                      color: "#c7c7c7",
                    }}
                  ></Typography>
                </Box>
              </Box>

              <Box style={{ width: "100%", textAlign: "center" }}>
                <SecondaryButton
                  onClick={handleBack}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginRight: "7px",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  Back
                </SecondaryButton>
                <ActionButton
                  disabled={disabledExecutionButton}
                  onClick={CreateExecution}
                  style={{
                    alignWtems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "24px",
                    display: "inline-block",
                  }}
                >
                  {ifScheduled === 0 ? "Execute" : "Scheduled"}
                </ActionButton>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
