import React, { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import {
  Box,
  ThemeProvider,
  IconButton,
  Button,
  Tooltip,
  CircularProgress,
  Menu,
  Typography,
  TablePagination,
} from "@mui/material";
import ExporExcelMiddle from "../../ayudas/ExporExcelMiddle";
import {
  pageTheme,
  StyledDataGrid,
  MaterialUISwitch,
  CustomLoadingOverlay,
  QuickSearchToolbar,
  escapeRegExp,
  PrimaryButton,
} from "../../ayudas/Themes";
import AgentIcon from "../../assets/images/agents-icon.svg";
import ExecutionIcon from "../../assets/images/execution-icon.svg";
import ConnexionIcon from "../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../assets/images/not-connected-icon.svg";
import WifiDisabled from "../../assets/images/wifi-disabled.svg";
import WindowIcon from "../../assets/images/window-icon.svg";
import LinuxIcon from "../../assets/images/linux-icon.svg";
import MacIcon from "../../assets/images/apple-logo.svg";
import UnkwonIcon from "../../assets/images/device-unknown-icon.svg";

import ForceDirectedTree from "../../Scripts/AgentForceDirectedTree";
import PieChartAgent from "../../Scripts/SuccessandFail";
import PieChartAgentNetwork from "../../Scripts/NetworkSecurityEvation";

import { DesktopMacTwoTone, Info, MoreVert } from "@mui/icons-material";

import DetailmodalAgent from "./DetailAgents/DetailAgent";
import Deletemodal from "./DeletAgent/DeleteAgent";

import { GetClientReportAsync } from "../../Services/Reports";
import AgentReport from "./AgentReport/AgentReport";
import { GridOverlay } from "@mui/x-data-grid-pro";

import axios from "axios";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import Installers from "../Installers/Installers";
import { env2 } from "../../ayudas/Instance";
import {
  criticalColors,
  GenerateDataClients,
  GenerateDataTable,
  GenerateParamsSuccesAndFails,
} from "./Common/funcionesayuda/FunctionsHelpers";
import TablePaginationActions from "../../ayudas/TablePaginations";

export default function DataGridDemo(props) {
  const { DataUser, Reload, ResetInsider } = props;

  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortModel, setSortModel] = useState([
    { field: "online", sort: "desc" },
  ]);
  const [selectAgent, setselectAgent] = useState("");
  const [totalNoOfExecutions, settotalNoOfExecutions] = useState(0);
  const [TableData, setTableData] = useState([]);
  const [GraficNetwork, setGraficNetwork] = useState(false);
  const [DataChild, setDataChild] = useState();
  const [ipGroups, setipGroups] = useState([]);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const rowsPerPage = 10;
  const [signalToReload, setSignalToReload] = useState(false);

  const columns = [
    {
      field: "online",
      headerName: "Online",
      flex: 0.5,
      minWidth: 50,
      align: "center",
      type: "boolean",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <DesktopMacTwoTone style={{ verticalAlign: "middle" }} />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.value ? (
            <>
              <Box sx={{ width: "50%" }}>
                {!params.row.updated ? (
                  <Tooltip title="Endpoint Outdated">
                    <Info
                      fontSize="small"
                      style={{ color: "orange", marginRight: "7px" }}
                    />
                  </Tooltip>
                ) : null}
              </Box>
              <Box sx={{ width: "50%" }}>
                <Tooltip title="Connected">
                  <img
                    src={ConnexionIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                  />
                </Tooltip>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ width: "50%" }}>
                {!params.row.updated ? (
                  <Tooltip title="Endpoint Outdated">
                    <Info
                      fontSize="small"
                      style={{ color: "orange", marginRight: "7px" }}
                    />
                  </Tooltip>
                ) : null}
              </Box>

              <Box sx={{ width: "50%" }}>
                <Tooltip title="Disconnected">
                  <img
                    src={DesconnexionIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                  />
                </Tooltip>
              </Box>
            </>
          )}
        </>
      ),
    },
    {
      field: "OS",
      headerName: "Platform",
      flex: 0.5,
      minWidth: 50,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Platform
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.OS === 1 ? (
            <img src={WindowIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
          {params.row.OS === 2 ? (
            <img src={LinuxIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
          {params.row.OS === 3 ? (
            <img src={MacIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
          {params.row.OS === 0 ? (
            <img src={UnkwonIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
        </>
      ),
    },
    {
      field: "hostname",
      headerName: "Endpoints",
      flex: 1,
      minWidth: 200,

      renderCell: (params) => (
        <Box sx={{ alignContent: "center", height: "100%" }}>
          <Typography
            style={{
              color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
            }}
          >
            {params.row?.alias ? params.row.alias : params.row.hostname}
          </Typography>
        </Box>
      ),
    },

    {
      field: "private",
      headerName: "Private IP address",
      flex: 0.5,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          style={{
            alignContent: "center",
            height: "100%",
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "ip",
      headerName: "Public IP address",
      flex: 0.5,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          style={{
            alignContent: "center",
            height: "100%",
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "isolation",
      headerName: "Isolation",
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
            fontWeight: "600",
            alignContent: "center",
            height: "100%",
          }}
        >
          Isolation
        </Box>
      ),
      renderCell: (params) => (
        <>
          {!params.row.online ? (
            <img
              src={WifiDisabled}
              alt=""
              style={{ textAlign: "center", width: 30 }}
            />
          ) : (
            <>
              {params.value === "Pr" ? (
                <CircularProgress sx={{ marginTop: "15px" }} />
              ) : (
                <Tooltip
                  title={
                    params.value ? "Network isolated" : "Network Connected"
                  }
                >
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    disabled={
                      DataUser.user.result.rol !== "admin" ||
                      !params.row.online ||
                      params.row.OS === 2
                    }
                    checked={params.value}
                    onClick={isolationChange.bind(this, params.row.id)}
                    color="info"
                  />
                </Tooltip>
              )}
            </>
          )}
        </>
      ),
    },
    {
      field: "history",
      headerName: "Emulation History",
      flex: 0.5,
      minWidth: 150,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Emulation History
        </Box>
      ),
      renderCell: (params) => (
        <Button
          disabled={params.row.fkaghis}
          onClick={() => {
            generateHistoric(params);
          }}
          style={{
            backgroundColor: "#b69141",
            borderRadius: "14px",
            fontsize: "15px",
            lineHeight: "22px",
            color: "#fff",
            textAlign: "center",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "14px 20px",
            border: "none",
            cursor: "pointer",
          }}
        >
          <img src={ExecutionIcon} alt="" id={"ButtonHistoric" + params.id} />
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 0.5,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Actions
        </Box>
      ),
      renderCell: (params) => (
        <>
          <DetailmodalAgent
            Client={params.row}
            SelectIcon={true}
            ipGroups={ipGroups}
            DataUser={DataUser}
            DetailsOff={true}
            ResetInsider={ResetInsider}
            Reload={ReloadData}
          />
          <Deletemodal
            row={params.row}
            DataUser={DataUser}
            Reload={ReloadData}
          />
        </>
      ),
    },
  ];

  const isolationChange = (_id) => {
    let array = [...rows];
    for (let index = 0; index < array.length; index++) {
      if (array[index].id === _id) {
        const Client = _id,
          Isolation = array[index].isolation ? 0 : 1;
        array[index].isolation = "Pr";
        setRows(array);

        const Request = { Client, Isolation };
        props.Isolation(Request);
        break;
      }
    }
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);

    if (searchValue === "") {
      setRows(totalRows);
    }
  };
  const ReloadData = () => {
    setSignalToReload(true);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (Reload !== undefined || signalToReload) {
      cookies.set("CurrentPageDataPagination", "4", {
        path: "/",
        SameSite: "none",
        secure: true,
      });

      const url = env2.api + endPoint.clients;
      async function GetData() {
        const response = await AxiosFetch("GET", url, source);
        let array = [];
        if (response?.msg) {
          array = await GenerateDataClients(response.msg);

          setRows(array);
          settotalRows(array);
        } else {
          setRows([]);
          settotalRows([]);
        }
      }
      GetData();
      setSignalToReload(false);
    }
    return () => {
      source.cancel();
    };
  }, [Reload, cookies, signalToReload]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (Reload !== undefined) {
      const url = env2.api + endPoint.groupsCat;
      async function GetData() {
        const response = await AxiosFetch("GET", url, source);
        if (response) {
          setipGroups(response.msg.result);
        }
      }
      GetData();
    }
    return () => {
      source.cancel();
    };
  }, [Reload]);
  const [allDataReport, setallDataReport] = useState([]);
  const generateHistoric = async (data) => {
    let array = [...rows];
    array.forEach((element) => {
      if (element.id === data.id) {
        element.fkaghis = true;
      }
    });
    setRows(array);
    const response = await GetClientReportAsync(data.id);
    if (response) {
      setselectAgent(data.row.hostname);
      ForceGrapichConstructor(data.row.hostname, response.client);
      GenereteGrafic(response.client);
      setallDataReport(response.client);
      GenerateDataTablefunction(response.client);
      array.forEach((element) => {
        if (element.id === data.id) {
          element.fkaghis = false;
        }
      });
    }
  };

  const ForceGrapichConstructor = (agentName, array) => {
    let Childrens = [];
    let executionChildren = [];
    let packChildren = [];
    var noOfSuccessDownload = 0,
      noOfFailedDownload = 0;
    array.executions.forEach((execution) => {
      if (execution.Reports.length > 0) {
        executionChildren = [];
        execution.Reports.forEach((report, index) => {
          if (report.historys.packages.length > 0) {
            report.historys.packages.forEach((pack) => {
              if (pack.artifacts.length > 0) {
                packChildren = [];
                pack.artifacts.forEach((artifact) => {
                  packChildren.push({
                    name: artifact.name.slice(0, 8) + "...",
                    tooltip: artifact.name,
                    value: 30,
                    color: criticalColors(artifact.threatType),
                  });
                  artifact.history.forEach((downloadVal) => {
                    if (downloadVal.event === "survivedowlandfile") {
                      if (downloadVal.value === 1) {
                        noOfSuccessDownload++;
                      } else {
                        noOfFailedDownload++;
                      }
                    }
                  });
                });
              }
              executionChildren.push({
                name: pack.name.slice(0, 10) + "...",
                tooltip: pack.name,
                value: 50,
                color: "#e328af",
                children: packChildren,
              });
            });
          }
        });
      }
      Childrens.push({
        value: 70,
        color: "#9f30f6",
        name: execution.name.slice(0, 15) + "...",
        tooltip: execution.name,
        children: executionChildren,
      });
    });
    let data = [
      {
        name: agentName.slice(0, 15) + "...",
        tooltip: agentName.slice(0, 15) + "...",
        children: Childrens,
        value: 100,
        color: "#25BEC1",
      },
    ];
    var downloadArr = [
      {
        status: "Success",
        value: ((noOfSuccessDownload * 100) / noOfSuccessDownload).toFixed(0),
        neto: noOfSuccessDownload,
      },
      {
        status: "Failed",
        value: ((noOfFailedDownload * 100) / noOfFailedDownload).toFixed(0),
        neto: noOfFailedDownload,
      },
    ];
    let boolValue =
      downloadArr[0].value === 0 && downloadArr[1].value === 0
        ? false
        : (downloadArr[0].value = "NaN" ? false : true);

    setGraficNetwork(boolValue);
    setDataChild(data);
    PieChartAgentNetwork(downloadArr);
    ForceDirectedTree(data);
  };

  const GenerateDataTablefunction = (array) => {
    let dataTable = GenerateDataTable(array);
    setTableData(dataTable);
  };

  const GenereteGrafic = (array) => {
    let objectExectution = GenerateParamsSuccesAndFails(array);
    settotalNoOfExecutions(objectExectution.totalExecution);
    PieChartAgent(objectExectution.topStatus);
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";

  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <PrimaryButton>
          <ExporExcelMiddle pageOrigin="Agents" fileName="EVE Agent Export" />
        </PrimaryButton>

        <Installers DataUser={DataUser} />
      </Menu>
    </Box>
  );

  return (
    <>
      {selectAgent === "" ? (
        <Box style={{ width: "100%" }}>
          <ThemeProvider theme={pageTheme}>
            <Typography variant="pageTitle">
              <img src={AgentIcon} alt="" className="rt-icon-header" />
              Endpoints
              <Box
                sx={{
                  float: { xs: "none", md: "right" },
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Box style={{ display: "inline-block" }}>
                  <ExporExcelMiddle
                    pageOrigin="Agents"
                    fileName="EVE Agent Export"
                  />
                </Box>

                <Installers DataUser={DataUser} />
              </Box>
              <Box
                sx={{
                  float: { xs: "right", md: "none" },
                  display: { xs: "inline-block", md: "none" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreVert />
                </IconButton>
              </Box>
            </Typography>

            {renderMobileMenu}

            <Box
              style={{
                width: "100%",
                backgroundColor: "#242933",
                border: " 1px solid #090f1a",
                padding: "15px",
                boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                borderRadius: "14px",
                marginTop: "24px",
                height: "auto",
              }}
            >
              <div style={{ height: "auto", width: "100%" }}>
                <StyledDataGrid
                  slotProps={{
                    pagination: {
                      ActionsComponent: TablePaginationActions,
                      showFirstButton: true,
                      showLastButton: true,
                    },
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                  slots={{
                    toolbar: QuickSearchToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: () => (
                      <GridOverlay>
                        <div>No Data Results</div>
                      </GridOverlay>
                    ),
                    NoResultsOverlay: () => (
                      <GridOverlay>
                        <div>No Results Filter Data</div>
                      </GridOverlay>
                    ),
                    Pagination: () => (
                      <>
                        <TablePagination
                          component="div"
                          pageSizeOptions={[5, 10, 20]}
                          showLastButton
                          showFirstButton
                        />
                      </>
                    ),
                  }}
                  showLastButton
                  showFirstButton
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: { paginationModel: { pageSize: rowsPerPage } },
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                  pagination
                  pageSizeOptions={[5, 10, 20]}
                  density="comfortable"
                />
              </div>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <AgentReport
          allDataReport={allDataReport}
          selectAgent={selectAgent}
          totalNoOfExecutions={totalNoOfExecutions}
          GraficNetwork={GraficNetwork}
          DataChild={DataChild}
          TableData={TableData}
          DataUser={DataUser}
          ReturnPage={() => {
            setselectAgent("");
          }}
        />
      )}
    </>
  );
}
