import {
  Box,
  Grid2,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { pageTheme, StyledDataGrid } from "../../../ayudas/Themes";

import ArrowIcon from "../../../assets/images/back-arrow-step.svg";
import ExcelIcon from "../../../assets/images/excel-icon.svg";

import SuccessandFail from "../GraphicSuccess";
import NetworkSecurityEvation from "../GraphicNetwork";
import AgentTree from "../Diagram";
import ExporExcelMiddle from "../../../ayudas/ExporExcelMiddle";
import PackModel from "../PackageDetails/PackageDetail";
import { ConvertDateUtc } from "../../../ayudas/GlobalData";
import PieChartAgentNetwork from "../../../Scripts/NetworkSecurityEvation";
import ForceDirectedTree from "../../../Scripts/AgentForceDirectedTree";
import {
  criticalColors,
  GenerateParamsSuccesAndFails,
} from "../Common/funcionesayuda/FunctionsHelpers";
import { useEffect } from "react";
import PieChartAgent from "../../../Scripts/SuccessandFail";

export default function AgentReport(props) {
  const {
    selectAgent,
    totalNoOfExecutions,
    DataChild,
    TableData,
    allDataReport,
  } = props;
  const ReturnPage = () => {
    props.ReturnPage("");
  };
  useEffect(() => {
    if (allDataReport) {
      let Childrens = [];
      let executionChildren = [];
      let packChildren = [];
      var noOfSuccessDownload = 0,
        noOfFailedDownload = 0;
      allDataReport.executions.forEach((execution) => {
        if (execution.Reports.length > 0) {
          executionChildren = [];
          execution.Reports.forEach((report, index) => {
            if (report.historys.packages.length > 0) {
              report.historys.packages.forEach((pack) => {
                if (pack.artifacts.length > 0) {
                  packChildren = [];
                  pack.artifacts.forEach((artifact) => {
                    packChildren.push({
                      name: artifact.name.slice(0, 8) + "...",
                      tooltip: artifact.name,
                      value: 30,
                      color: criticalColors(artifact.threatType),
                    });
                    artifact.history.forEach((downloadVal) => {
                      if (downloadVal.event === "survivedowlandfile") {
                        if (downloadVal.value === 1) {
                          noOfSuccessDownload++;
                        } else {
                          noOfFailedDownload++;
                        }
                      }
                    });
                  });
                }
                executionChildren.push({
                  name: pack.name.slice(0, 10) + "...",
                  tooltip: pack.name,
                  value: 50,
                  color: "#e328af",
                  children: packChildren,
                });
              });
            }
          });
        }
        Childrens.push({
          value: 70,
          color: "#9f30f6",
          name: execution.name.slice(0, 15) + "...",
          tooltip: execution.name,
          children: executionChildren,
        });
      });
      let data = [
        {
          name: selectAgent.slice(0, 15) + "...",
          tooltip: selectAgent.slice(0, 15) + "...",
          children: Childrens,
          value: 100,
          color: "#25BEC1",
        },
      ];
      var downloadArr = [
        {
          status: "Success",
          value: ((noOfSuccessDownload * 100) / noOfSuccessDownload).toFixed(0),
          neto: noOfSuccessDownload,
        },
        {
          status: "Failed",
          value: ((noOfFailedDownload * 100) / noOfFailedDownload).toFixed(0),
          neto: noOfFailedDownload,
        },
      ];

      PieChartAgentNetwork(downloadArr);
      ForceDirectedTree(data);
      let objectExectution = GenerateParamsSuccesAndFails(allDataReport);
      PieChartAgent(objectExectution.topStatus);
    }
    return () => {};
  }, [allDataReport, selectAgent]);
  const columnsAgent = [
    {
      field: "Execution",
      headerName: "Emulation",
      flex: 2,
      minWidth: 100,
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Emulation</Box>
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            justifyContent: "center",
            display: "grid",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "Package",
      headerName: "Package",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <PackModel Data={params} />,
    },
    {
      field: "Success",
      headerName: "Success",
      flex: 0.2,
      minWidth: 50,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Success</Box>
      ),
    },
    {
      field: "Fail",
      headerName: "Fail",
      flex: 0.2,
      minWidth: 50,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Fail</Box>
      ),
    },
    {
      field: "DateString",
      headerName: "Date",
      type: "date",
      flex: 1,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          sx={{
            display: "grid",
            alignItems: "center",
            height: "100%",
          }}
        >
          {ConvertDateUtc(params.value)}
        </Typography>
      ),
    },
  ];
  return (
    <ThemeProvider theme={pageTheme}>
      <Box style={{ width: "100%" }}>
        <Box style={{ width: "100%" }}>
          <IconButton onClick={ReturnPage} style={{ display: "inline-block" }}>
            <img src={ArrowIcon} alt="" />
          </IconButton>
          <Typography
            style={{
              display: "inline-block",
              fontSize: "18px",
              color: "#ffffff",
              textTransform: "capitalize",
              fontWeight: "600",
            }}
          >
            {"Host Name > " + selectAgent}
          </Typography>
        </Box>
        <Box
          style={{
            backgroundColor: "#090f1a",
            border: "1px solid #1e1c3a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "12px",
            marginBottom: "24px",
          }}
        >
          <Box style={{ display: "flex!important" }}>
            <Typography variant="h6" style={{ display: "inline-block" }}>
              Emulation History Dashboard
            </Typography>
            <Box
              style={{
                display: "inline-block",
                float: "right",
                fontWeight: "600",
                fontSize: "15px",
                color: "#ffffff",
              }}
            >
              Host Name:
              <Box
                style={{
                  display: "inline-block",
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#ffffff",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                {selectAgent}
              </Box>
              <Box
                style={{
                  display: "inline-block",
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#ffffff",
                }}
              >
                |
              </Box>
              <Box
                style={{
                  display: "inline-block",
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#ffffff",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                Count of Emulations
              </Box>
              <Box
                style={{
                  display: "inline-block",
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#ffffff",
                  marginRight: "18px",
                }}
              >
                {totalNoOfExecutions}
              </Box>
            </Box>
          </Box>

          <Grid2 container spacing={3}>
            <Grid2 item size={6}>
              <SuccessandFail Title={"Artifact Execution Results"} />
            </Grid2>
            <Grid2 item size={6}>
              <NetworkSecurityEvation />
            </Grid2>
          </Grid2>
        </Box>
        <Box
          style={{
            backgroundColor: "#090f1a",
            border: "1px solid #1e1c3a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "12px",
            marginBottom: "24px",
          }}
        >
          <Typography style={{ fontSize: "21px", fontWeight: "500" }}>
            Prior 10 Emulations
          </Typography>
          <Box
            className="BackImage"
            style={{
              backgroundColor: "#0a081893",
              border: "1px solid #1e1c3a",
              padding: "15px",
              boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
              borderRadius: "12px",
              marginTop: "24px",
              minHeight: "300px",
              color: "#21273F",
              width: "100%",
            }}
          >
            <AgentTree Data={DataChild} />
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            backgroundColor: "#090f1a",
            border: "1px solid #1e1c3a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "12px",
            marginTop: "24px",
          }}
        >
          <Box
            style={{
              width: "100%",
              backgroundColor: "#242933",
              border: " 1px solid #090f1a",
              padding: "15px",
              boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
              borderRadius: "14px",
              marginTop: "24px",
              height: "auto",
            }}
          >
            <div
              style={{ height: "auto", width: "100%", marginBottom: "15px" }}
            >
              {TableData.length === 0 ? (
                <div
                  style={{
                    width: "170px",
                    margin: "8px",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 16,
                    padding: "10px 30px",
                    borderRadius: "12px",
                    border: "1px solid gray",
                    lineHeight: 1.5,
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "transparent",
                    color: "darkgray",
                  }}
                >
                  {" "}
                  <img
                    src={ExcelIcon}
                    alt=""
                    style={{ width: "20px", marginRight: "15px" }}
                  />
                  No rows
                </div>
              ) : (
                <ExporExcelMiddle
                  pageOrigin="AgentsHistory"
                  externalData={TableData}
                  fileName={"E.V.E. " + selectAgent + " Export"}
                />
              )}
            </div>
            <div style={{ height: "auto", width: "100%" }}>
              <StyledDataGrid
                rows={TableData}
                columns={columnsAgent}
                density="comfortable"
                autoHeight
                disableSelectionOnClick
              />
            </div>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
