import { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Grid,
  Modal,
  Tooltip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { Description, Star } from "@mui/icons-material";
import "../../BodyScriptPageStyles.scss";
import Eye from "../../../../../../assets/images/eye-icon.svg";

// import { PrimaryButton, SecondaryButton } from "../../../../../helpers/Themes";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
// import { GetDate, enqueueSnackbarSuccess } from "../../../../../helpers/Scripts";
// import Deletemodal from "../DeleteArtifact/DeteleArtifact";
// import DowloadModalArtifact from "../DownloadArtifact/DownloadArtifact";
// import FileUpload from "../../../../../helpers/CommonComponents/FileUpload";
import { GetScriptByIdAsync } from "../../../../Services/ServiceScriptPage";
import DowloadModalScript from "../DownloadScript/DownloadScript";
import { GetDate } from "../../../../../../ayudas/ScriptsFunctions";
import { SecondaryButton } from "../../../../../../ayudas/Themes";
import { enqueueSnackbar } from "notistack";

const styleCellName = {
  maxWidth: "150px",
  color: "#fff",
  borderColor: "transparent",
};

const styleCellDescript = { borderColor: "transparent", color: "gray" };
export default function DetailsScript(props) {
  const { ScriptData, DataUser, Choose, Information } = props;
  const [open, setOpen] = useState(false);
  const [ScriptDataDetails, setScriptDataDetails] = useState(null);

  const handleOpen = async () => {
    const response = await GetScriptByIdAsync(
      DataUser.token,
      typeof ScriptData?.id === "number" ? ScriptData._id : ScriptData.id
    );
    if (response !== null) {
      setScriptDataDetails(response);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(ScriptDataDetails.file.md5);
    enqueueSnackbar("Copied to Clipboard", { variant: "success" });
  };

  const body = (
    <Box
      className="Section"
      style={{
        backgroundcolor: "#66638f",
        borderBottom: "1px solid #1e1c3a",
        maxHeight: "700px",
        overflowY: "auto",
        overflowX: "hidden",
        minWidth: "800px",
      }}
    >
      {ScriptDataDetails ? (
        <Grid container>
          <Grid
            item
            xs={1}
            style={{
              backgroundColor: "rgb(13, 11, 31)",
              textAlign: "center",
              borderRadius: "14px 0 0 0",
              padding: "20px",
            }}
          >
            <Box>
              <Box style={{ display: Information ? "block" : "none" }}>
                <DowloadModalScript
                  scriptFile={ScriptData.row}
                  DataUser={DataUser}
                />
                {/* <Deletemodal
                  Reload={Reload}
                  id={artifactData._id}
                  DataUser={DataUser}
                /> */}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              backgroundColor: "rgb(13, 11, 31)",
              textAlign: "center",
              padding: "20px",
            }}
          >
            {ScriptDataDetails.tagTest === "tested" ? (
              <Box className="TagTest Good">
                {" "}
                <Description style={{ fontSize: "160px", display: "block" }} />
              </Box>
            ) : null}
            {ScriptDataDetails.tagTest === "PartiallyTested" ? (
              <Box className="TagTest Midd">
                {" "}
                <Description style={{ fontSize: "160px", display: "block" }} />
              </Box>
            ) : null}
            {ScriptDataDetails.tagTest === "untested" ? (
              <Box className="TagTest Fail">
                {" "}
                <Description
                  style={{ fontSize: "160px", display: "inline-block" }}
                />
              </Box>
            ) : null}
            <Typography align="center" variant="h6">
              <span
                style={{
                  height: "100px",
                  overflow: "hidden",
                  whiteSpace: "pre-wrap",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "3",
                  display: "-webkit-box",
                }}
              >
                {ScriptDataDetails.name}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              backgroundColor: "rgb(13, 11, 31)",
              borderRadius: "0 14px 0 0",
            }}
          >
            <Table size="small" style={{ marginTop: "32px" }}>
              <TableBody>
                <TableRow>
                  <TableCell align="right" style={styleCellName}>
                    <Typography>Platform:</Typography>
                  </TableCell>
                  <TableCell align="left" style={styleCellDescript}>
                    <Typography>
                      {ScriptDataDetails.platform === "GNULinux"
                        ? "GNU/Linux"
                        : ScriptDataDetails.platform}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={styleCellName}>
                    <Typography>Language:</Typography>
                  </TableCell>
                  <TableCell align="left" style={styleCellDescript}>
                    <Typography>
                      {ScriptDataDetails.language === "BATHWIN"
                        ? "bash"
                        : ScriptDataDetails.language === "BATHLinux"
                        ? "shell"
                        : ScriptDataDetails.language === "phython"
                        ? "python"
                        : ScriptDataDetails.language}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={styleCellName}>
                    <Typography>Last Modification:</Typography>
                  </TableCell>
                  <TableCell align="left" style={styleCellDescript}>
                    <Typography>
                      {GetDate(ScriptDataDetails.updatedAt)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={styleCellName}>
                    <Typography>MD5:</Typography>
                  </TableCell>
                  <TableCell align="left" style={styleCellDescript}>
                    <Typography style={{ display: "inline-flex" }}>
                      {ScriptDataDetails.file.md5}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={styleCellName}></TableCell>

                  <TableCell align="left" style={styleCellDescript}>
                    <SecondaryButton
                      style={{ display: "inline-flex" }}
                      sx={{ width: "80px", height: "35px" }}
                      onClick={copyToClipboard.bind()}
                    >
                      <ContentPasteIcon
                        style={{
                          color: "white",
                          fontSize: "20px",
                          margin: "4px 7px",
                        }}
                      />
                      <Typography
                        style={{ color: "white", marginRight: "7px" }}
                      >
                        Copy
                      </Typography>
                    </SecondaryButton>
                  </TableCell>
                </TableRow>
                {ScriptDataDetails?.parameters && (
                  <TableRow>
                    <TableCell align="right" style={styleCellName}>
                      <Typography>Parameters:</Typography>
                    </TableCell>
                    <TableCell align="left" style={styleCellDescript}>
                      <Typography>{ScriptDataDetails.parameters}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {ScriptDataDetails?.preBoot && (
                  <TableRow>
                    <TableCell align="right" style={styleCellName}>
                      <Typography>Permissions:</Typography>
                    </TableCell>
                    <TableCell align="left" style={styleCellDescript}>
                      <Typography>{ScriptDataDetails.preBoot}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "rgb(13, 11, 31)",
              padding: "0px 20px 0px 20px ",
            }}
          >
            <Typography>Description:</Typography>
            <Typography
              style={{
                maxWidth: "100%",
                textAlign: "justify",
                color: "rgb(102, 99, 143)",
                whiteSpace: "pre-wrap",
                height: "auto",
              }}
            >
              {ScriptDataDetails?.description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "rgb(13, 11, 31)",
              borderRadius: "0 0 14px 14px ",
              padding: "20px",
            }}
          ></Grid>
        </Grid>
      ) : null}
    </Box>
  );
  return (
    <Box
      style={{
        display: "inline-block",
        cursor: "pointer",
      }}
    >
      {Choose ? (
        <Typography
          onClick={handleOpen}
          onKeyDown={(event) => {
            if (event.key === " ") {
              // Prevent key navigation when focus is on button
              event.stopPropagation();
            }
          }}
          style={{
            fontFamily: "Roboto , Helvetica,Arial,sans-serif",
            fontWeight: "400",
            fontSize: "0.875rem",
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          {ScriptData.row?.source === "FILERAPTOR" ? (
            <Tooltip title={"E.V.E."}>
              <Star color="warning" style={{ fontSize: "12px" }} />
            </Tooltip>
          ) : null}
          <span
            style={{
              minWidth: "100px",
              maxWidth: "15vw",
              overflow: "hidden",
              textAlign: "justify",
              marginLeft: "7px",
              display: "block",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {ScriptData.row?.name
              ? ScriptData.row.name
              : ScriptData.name}
          </span>
        </Typography>
      ) : (
        <Tooltip title="File Details">
          <IconButton className="iconButtonSize">
            <img src={Eye} alt="" onClick={handleOpen} />
          </IconButton>
        </Tooltip>
      )}
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}

DetailsScript.defaultProps = {
  Information: true,
};
